@font-face {
	font-family: "nunito";
	src: local("Rajdhani"), url(./fonts/nunito_medium.ttf) format("truetype");
}
@font-face {
	font-family: "nunito_bold";
	src: local("Rajdhani"), url(./fonts/nunito_bold.ttf) format("truetype");
}
@font-face {
	font-family: "lato";
	src: local("Rajdhani"), url(./fonts/lato.ttf) format("truetype");
}
@font-face {
	font-family: "lato_bold";
	src: local("Rajdhani"), url(./fonts/lato_bold.ttf) format("truetype");
}
body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--font-size-xsmall: 0.7rem;
	--font-size-small: 0.9rem;
	--font-size-medium: 1.1rem;
	--font-size-large: 1.2rem;
	--font-size-h2: 1.5rem;
	--font-size-date: 1rem;
	--font-size-task: 1.1rem;

	--spacing-xxsmall: 3px;
	--spacing-xsmall: 4px;
	--spacing-small: 0.5rem;
	--spacing-medium: 1rem;
	--spacing-large: 2vw;

	--color-grey: grey;

	--font-family-secondary: "nunito", sans-serif;

	--border-dashed: 1px dashed lightgrey;
	--border-light: 1px solid var(--color-line);

	--letter-spacing-medium: 0.2rem;
}
