@font-face {
	font-family: "nunito";
	src: local("Nunito"), url(./fonts/nunito_medium.ttf) format("truetype");
}
@font-face {
	font-family: "nunito_bold";
	src: local("Nunito Bold"), url(./fonts/nunito_bold.ttf) format("truetype");
}
@font-face {
	font-family: "lato";
	src: local("Lato"), url(./fonts/lato.ttf) format("truetype");
}
@font-face {
	font-family: "lato_bold";
	src: local("Lato Bold"), url(./fonts/lato_bold.ttf) format("truetype");
}

:root {
	--color-background: #212836;
	--color-main: #ffab6e;
	--color-chart-bg: #e8e8e8;
	--color-white: #fff;
	--color-text: #35363d;
	--color-line: #404040;
	--color-light-text: #eeeeee;
	--color-light-border: #f3f3f3;

	--font-size-h2: 1.6rem;
	--spacing-medium: 1rem;
	--font-size-date: 1.3rem;
	--font-size-small: 1.1rem;

	--font-size-base: 16px;
	--font-size-h1: 2.5rem;
	--font-size-h2: 2rem;
	--font-size-h3: 1.75rem;
	--font-size-p: 1rem;

	--spacing-small: 0.5rem;
	--spacing-medium: 1rem;
	--spacing-large: 2rem;

	--border-radius: 8px;
}

body {
	font-size: var(--font-size-base);
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.75rem;
}

p {
	font-size: 1rem;
}

/* Add responsive styles */
@media (max-width: 768px) {
	:root {
		--font-size-base: 14px;
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	h3 {
		font-size: 1.5rem;
	}
}

* {
	font-family: "nunito_bold", sans-serif;
	color: var(--color-text);
	letter-spacing: 0.6px;
}

.App_content_fragment {
	width: 53%;
	margin-top: 5vw;
	margin-left: 5vw;
}

.only-mobile {
	display: none;
}

.desktop-only {
	display: flex;
}

hr {
	width: 100%;
	border: 1px solid var(--color-line);
	margin: 0;
	&.light {
		border: 1px solid var(--color-light-text);
	}
}

.bar {
	display: flex;
	margin-bottom: 1rem;
}
.tech {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (max-width: 768px) {
	.only-mobile {
		display: flex;
	}

	.desktop-only {
		display: none;
	}
}
